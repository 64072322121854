export default class SocketBox {
    constructor() {
        this.messageId = 1;
        this.waitingrequests = [];
        this.listeners = [];
    }
    connect(url) {
        this.url = url;
        console.log("[SocketBox.js] Connecting to "+url);
        this.socket = new WebSocket(url, "socketbox");
        this.socket.onopen = () => this.onopen(this);
        this.socket.onclose = () => this.onclose(this);
        this.socket.onmessage = (m) => this.onmessage(m, this);
    }
    onopen(_sb) {
        console.log("[SocketBox.js] Connected to "+_sb.url);
        _sb.runAction("connect");
    }
    async onclose(_sb) {
        console.log("[SocketBox.js] Disconnected from "+_sb.url);
        _sb.runAction("disconnect");
        await new Promise(resolve => setTimeout(resolve, 3000));
        _sb.connect(this.url);
    }
    onmessage(message, _sb) {
        var json;
        try {
            json = JSON.parse(message.data);
        } catch(e) {
            return;
        }
        for(var i=0; i<_sb.waitingrequests.length; i++) {
            if(json.header.reply == _sb.waitingrequests[i].id) _sb.waitingrequests[i].resolve(json.data);
        }
        if(json.data.action) {
            _sb.runAction("action", json.data);
            switch(json.data.action) {
                case "ping":
                    this.send({action: "pong"}, json.header.id);
                    break;
            }
        } else {
            _sb.runAction("message", json.data);
        }
    }
    send(data, reply = false) {
        var json = {
            header: {},
            data: data
        };
        json.header.id = this.messageId;
        this.messageId++;
        if(reply) json.header.reply = reply;

        this.socket.send(JSON.stringify(json));
        return json;
    }
    async request(action, data = {}) {
        data.action = action;
        var msg = this.send(data);
        return await this.waitformessage(msg.header.id);
    }
    async waitformessage(id) {
        var promise = new Promise((resolve, reject) => {
            this.waitingrequests.push({id, resolve});
        });
        return promise;
    }
    async ping() {
        return new Promise(async (resolve, reject) => {
            setTimeout(() => resolve("timeout"), 10000);
            try {
                var startTime = (new Date()).getTime();
                await this.request("ping");
                resolve(((new Date()).getTime()) - startTime);
            } catch(e) {
                resolve(-1);
            }
        });
    }
    listen(type, callback) {
        this.listeners.push({type, callback});
    }
    runAction(type, data) {
        this.listeners.forEach(l => {
            if(l.type == type) l.callback(data);
        });
    }
}