import "./style.css";
import "@mdi/font/css/materialdesignicons.css";
import Phaser from "phaser";

import SocketBox from "./socketbox";
var sb = new SocketBox();
sb.listen("message", msg => {
    console.log("Message:", msg);
});
sb.listen("connect", async () => {
    console.log(await sb.ping());
});
sb.connect("wss://game.trp.im/server/");

import DirtBlock from "./img/dirt.png";
import GrassBlock from "./img/grass_block.png";
import Sky from "./img/sky.png";
import Char1 from "./img/char1.png";
import Char2 from "./img/char2.png";
import { Game } from "./game";

var game = new Game();
globalThis.game = game;

game.setXY(0, 1);
game.setSB(sb);

document.onkeydown = function (e) {
    if(e.code == "KeyA" || e.code == "ArrowLeft") {game.moves.left = true;}
    if(e.code == "KeyD" || e.code == "ArrowRight") {game.moves.right = true;}
    if(e.code == "Space" || e.code == "KeyW" || e.code == "ArrowUp") {game.moves.jump = true;}
};
document.onkeyup = function (e) {
    if(e.code == "KeyA" || e.code == "ArrowLeft") {game.moves.left = false;}
    if(e.code == "KeyD" || e.code == "ArrowRight") {game.moves.right = false;}
    if(e.code == "Space" || e.code == "KeyW" || e.code == "ArrowUp") {game.moves.jump = false;}
};


var game_data = {};

function components() {
    let elements = [];

    let gameEl = document.createElement('div');
    gameEl.id = "game";
    gameEl.className = "game";
    elements.push(gameEl);

    let bottomBar = document.createElement('div');
    bottomBar.className = "bottomBar";

    let goLeft = document.createElement('div');
    goLeft.className = "btn goLeft mdi mdi-chevron-left";
    goLeft.onmousedown = goLeft.ontouchstart = () => game.moves.left = true;
    goLeft.onmouseup = goLeft.ontouchend = () => game.moves.left = false;
    bottomBar.appendChild(goLeft);

    let goRight = document.createElement('div');
    goRight.className = "btn goRight mdi mdi-chevron-right";
    goRight.onmousedown = goRight.ontouchstart = () => game.moves.right = true;
    goRight.onmouseup = goRight.ontouchend = () => game.moves.right = false;
    bottomBar.appendChild(goRight);

    let jump = document.createElement('div');
    jump.className = "btn jump mdi mdi-chevron-up";
    jump.onmousedown = jump.ontouchstart = () => game.moves.jump = true;
    jump.onmouseup = jump.ontouchend = () => game.moves.jump = false;
    bottomBar.appendChild(jump);

    elements.push(bottomBar);

    return elements;
}

components().every(component => document.body.appendChild(component));



function preload() {

    this.load.image('grass_block', GrassBlock);
    this.load.image('dirt', DirtBlock);

    this.load.image('sky', Sky);
    this.load.spritesheet('char1', Char1, { frameWidth: 40, frameHeight: 60});
    this.load.spritesheet('char2', Char2, { frameWidth: 33, frameHeight: 51});

}

function create() {

    let camera = this.cameras.main;
    camera.setBackgroundColor('#4EB6D8');

    let player = this.physics.add.sprite(game.centerX, game.centerY, 'char1');
    player.setDepth(100);

    this.anims.create({
        key: 'char1_left',
        frames: this.anims.generateFrameNumbers('char1', { start: 0, end: 3 }),
        frameRate: 10,
        repeat: -1
    });
    
    this.anims.create({
        key: 'char1_turn',
        frames: [ { key: 'char1', frame: 4 } ],
        frameRate: 20
    });

    this.anims.create({
        key: 'char1_right',
        frames: this.anims.generateFrameNumbers('char1', { start: 5, end: 8 }),
        frameRate: 10,
        repeat: -1
    });

    game.setEntities({
        normal_blocks: this.physics.add.staticGroup(),
        player
    });

    /*normalblocks.create(startX+(blocksize*-1), startY+(blocksize*2), 'grass_block').setScale(2);
    normalblocks.create(startX+(blocksize*0), startY+(blocksize*2), 'dirt').setScale(2);
    normalblocks.create(startX+(blocksize*1), startY+(blocksize*2), 'grass_block').setScale(2);
    
    normalblocks.create(startX+(blocksize*2), startY+(blocksize*1), 'grass_block').setScale(2);*/

    game.loadTerrain();

    this.physics.add.collider(game.entities.player, game.entities.normal_blocks);

}

function update() {

    game.renderNearRegions(game.entities.player, 3);

    if(!(game.moves.left && game.moves.right)) {
        if (game.moves.left) {
            game.entities.player.setVelocityX(-160);
        
            game.entities.player.anims.play('char1_left', true);
        }
        else if(game.moves.right) {
            game.entities.player.setVelocityX(160);
        
            game.entities.player.anims.play('char1_right', true);
        }
        else {
            game.entities.player.setVelocityX(0);
        
            game.entities.player.anims.play('char1_turn');
        }
    }
    if(game.moves.jump && game.entities.player.body.touching.down) {
        game.entities.player.setVelocityY(-370);
    }

    if(game.entities.player.x < game.centerX/2 || game.entities.player.x > game.centerX*1.5) {
        if(game_data.lastX) {
            if(Math.abs(game.entities.player.x - game.centerX) > Math.abs(game_data.lastX - game.centerX)) game.moveView(game.entities.player.x - game_data.lastX, 0);
        }
        game_data.lastX = game.entities.player.x;
    } else {
        game_data.lastX = false;
    }

    if(game.entities.player.y < game.centerY/2 || game.entities.player.y > game.centerY*1.5) {
        if(game_data.lastY) {
            if(Math.abs(game.entities.player.y - game.centerY) > Math.abs(game_data.lastY - game.centerY)) game.moveView(0, game_data.lastY - game.entities.player.y);
        }
        game_data.lastY = game.entities.player.y;
    } else {
        game_data.lastY = false;
    }

}

var config = {
    type: Phaser.AUTO,
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
    parent: 'game',
    pixelArt: true,
    physics: {
        default: 'arcade',
        arcade: {
            gravity: { y: 0 },
            debug: false
        }
    },
    scene: {
        preload: preload,
        create: create,
        update: update
    }
};

game.setPhaser(new Phaser.Game(config));