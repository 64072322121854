export class Game {

    constructor() {
        this.moves = {
            left: false,
            right: false,
            jump: false
        };
        this.regionSize = 10;
        this.blockSize = 32;
        this.renderedRegions = [];
        this.setWindow();
    }

    setWindow() {
        this.width = document.documentElement.clientWidth;
        this.height = document.documentElement.clientHeight;

        this.centerX = this.width/2;
        this.centerY = this.height/2;
    }

    setEntities(entities) {
        this.entities = entities;
    }

    setXY(x, y) {
        this.viewX = x;
        this.viewY = y + .3;
    }

    setSB(sb) {
        this.sb = sb;
    }

    setPhaser(phaser) {
        this.phaser = phaser;
    }

    regionFromXY(x, y) {
        return [Math.floor(x/this.regionSize), Math.floor(y/this.regionSize), -(x%this.regionSize), -(y%this.regionSize)];
    }
    
    renderRegion(blocks, region, startX, startY, offsetX = 0, offsetY = 0) {
        region.blocks.forEach(block => {
            let width = (startX + (this.blockSize*(block.x)) ) + offsetX*this.blockSize;
            let height = (startY + (this.blockSize* (this.regionSize - block.y))) - (this.regionSize + offsetY) * this.blockSize;
            let rendered_block = blocks.create(width, height, block.block).setScale(2);
            region.rendered_blocks.push(rendered_block);
        });
    }
    
    async renderNearRegions(player, radius) {
        var [currentX, currentY] = this.viewXY(player);
        var [regionX, regionY, offsetX, offsetY] = this.regionFromXY(currentX, currentY);
    
        for (var i = 0-radius; i<=0+radius; i++) {
            for (var j = 0-radius; j<=0+radius; j++) {
                var region_id = (i+regionX)+"x"+(j+regionY);
                if(this.renderedRegions.indexOf(region_id) == -1) {
                    console.log(regionX, regionY);
                    this.renderedRegions.push(region_id);
                    var region = await this.sb.request("region", {id: region_id});
                    [currentX, currentY] = this.viewXY(player);
                    [regionX, regionY, offsetX, offsetY] = this.regionFromXY(currentX, currentY);
                    this.renderRegion(this.entities.normal_blocks, region, player.x, player.y, offsetX+i*this.regionSize, offsetY+j*this.regionSize);
                }
            }
        }
    }

    async loadTerrain() {
        await this.renderNearRegions(this.entities.player, 3);
        this.entities.player.setGravity(0,1000);
    }
    
    viewXY(player) {
        return [
            this.viewX + (player.x-this.centerX)/this.blockSize,
            this.viewY + (this.centerY-player.y)/this.blockSize
        ];
    }
    
    moveView(x, y) {
        this.entities.normal_blocks.children.getArray().forEach(b => {
            b.x -= x; b.y += y; b.setScale(1);
        });
        this.entities.player.x -= x;
        this.entities.player.y += y;
        this.entities.normal_blocks.refresh();
        this.entities.normal_blocks.children.getArray().forEach(b => b.setScale(2));
        this.viewX += x/this.blockSize;
        this.viewY += y/this.blockSize;
    }

}